@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bcard__profile-img{
    width: var(--bcard-width);
    height: var(--bcard-width);
    border-radius: 10px 10px 0px 0px;
}


.bcard__body{
    background-color: #1A1B21;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}

.bcard__heading-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
}

.bcard__heading-wrapper * + * {
    margin-top: 0.5em;
}

.bcard__name{
    color: var(--white);
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 30px;
}

.bcard__title{
    color: var(--secondary);
    font-weight: 400;
    font-size: 1.28rem;
    text-align: center;
}

.bcard__btn-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.bcard__btn{
    min-width: 11.5rem;
    height: 3.4rem;
    cursor: pointer;
}

.bcard__btn-icon{
    margin-right: 1rem;
    font-size: 1.4rem;
}

.bcard__btn--email{
    background: var(--white);
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.bcard__btn--linkedin{
    background: #5093E2;
    color: var(--white);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.bcard__text-wrapper{
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
}

.bcard__text-wrapper * + * {
    margin-top: 1em;
}

.bcard__heading{
    color: var(--whitish);
    font-size: 1.6rem;
}

.bcard__text{
    color: grey;
    font-size: 1.08rem;
}
.bcard__footer{
    height: 6.5rem;
    background-color: var(--bcard-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
}

.bcard__footer-icon{
    background-color: #918E9B;
    margin-right: 2.4rem;
    border-radius: 5px;
    cursor: pointer;
}

.bcard__footer-icon svg{
    font-size: 2.7rem;
    padding: 0.2em;
}

.bcard__footer-icon:last-of-type{
    margin-right: 0;
}


:root{
  --body-bg : #23252C;
  --bcard-bg : #1A1B21;
  --white : #FFFFFF;
  --whitish : #f5f5f5;
  --grey : #DCDCDC;
  --secondary : #F3BF99;

  --bcard-width : 31.7rem;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html{
  font-size: 62.5%;
}

body{
  background-color: #23252C;
  background-color: var(--body-bg);
  font-family: 'Inter', sans-serif;
}

a{
  all: unset;
}

/*a:not(.default):link    { 
  color: var(--whitish);
  text-decoration: none; 
}
a:not(.default):visited { 
  color: var(--whitish);
  text-decoration: none; 
}*/


.main{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bcard{
  width: 31.7rem;
  width: var(--bcard-width);
  margin: 1rem;
}

@media (max-height: 750px) {
  .main {
    /* do something.. */
    height: auto;
  }
}
