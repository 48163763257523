.bcard__body{
    background-color: #1A1B21;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}

.bcard__heading-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
}

.bcard__heading-wrapper * + * {
    margin-top: 0.5em;
}

.bcard__name{
    color: var(--white);
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 30px;
}

.bcard__title{
    color: var(--secondary);
    font-weight: 400;
    font-size: 1.28rem;
    text-align: center;
}

.bcard__btn-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.bcard__btn{
    min-width: 11.5rem;
    height: 3.4rem;
    cursor: pointer;
}

.bcard__btn-icon{
    margin-right: 1rem;
    font-size: 1.4rem;
}

.bcard__btn--email{
    background: var(--white);
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.bcard__btn--linkedin{
    background: #5093E2;
    color: var(--white);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.bcard__text-wrapper{
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
}

.bcard__text-wrapper * + * {
    margin-top: 1em;
}

.bcard__heading{
    color: var(--whitish);
    font-size: 1.6rem;
}

.bcard__text{
    color: grey;
    font-size: 1.08rem;
}