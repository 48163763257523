@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root{
  --body-bg : #23252C;
  --bcard-bg : #1A1B21;
  --white : #FFFFFF;
  --whitish : #f5f5f5;
  --grey : #DCDCDC;
  --secondary : #F3BF99;

  --bcard-width : 31.7rem;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html{
  font-size: 62.5%;
}

body{
  background-color: var(--body-bg);
  font-family: 'Inter', sans-serif;
}

a{
  all: unset;
}

/*a:not(.default):link    { 
  color: var(--whitish);
  text-decoration: none; 
}
a:not(.default):visited { 
  color: var(--whitish);
  text-decoration: none; 
}*/


.main{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bcard{
  width: var(--bcard-width);
  margin: 1rem;
}

@media (max-height: 750px) {
  .main {
    /* do something.. */
    height: auto;
  }
}